import { Button} from "reactstrap";
import React from "react";

const Numbers = ({ addNumber }) => {
  const lists = React.useMemo(() => {
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].sort(
      () => Math.random() - 0.5
    );
    const list1 = numbers.slice(0, 5);
    const list2 = numbers.slice(5, 10);
    return [list1, list2];
  }, []);

  return (
    <>
      {lists.map((numbers, index) => (
        <div className="line-number" key={index}>
          {numbers.map((i, key) => (

              <Button
                size="lg"
                className="code-btn"
                onClick={() => addNumber(i)}
              >
                {i}
              </Button>

          ))}
        </div>
      ))}
    </>
  );
};

export default Numbers;
