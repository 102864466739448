import React from "react";
import { Row } from "reactstrap";

function NoMatch() {
  return (
    <Row className="text-center">
      <div className="bad-page">
        <p>
          <span className="text-primary">O</span>OPS, Mauvaise page !
        </p>
      </div>
    </Row>
  );
}

export default NoMatch;
