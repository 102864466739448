import FormCode from "../components/FormCode";
import NoMatch from "../components/NoMatch";

const allRoutes = [
  {
    path: "/token/:token",
    component: FormCode,
    exact: true,
  },
  {
    path: "/*",
    component: NoMatch,
  },
];

export { allRoutes };
