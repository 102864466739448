import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { allRoutes } from "./routes/allRoutes";
import Menu from "./components/Menu";
import { Container } from "reactstrap";
import Top from "./components/Top";

function App() {
  return (
    <>
      <Router>
        <Menu />
        <Container className="mt-4 mb-4 main-container">
          <Top />
          <Switch>
            {allRoutes.map(
              ({ component: Component, exact, strict, path, ...rest }, i) => (
                <Route
                  path={path}
                  exact={exact || false}
                  strict={strict || false}
                  key={i}
                >
                  <Component {...rest} />
                </Route>
              )
            )}
          </Switch>
        </Container>
      </Router>
    </>
  );
}

export default App;
