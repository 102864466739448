import React, { useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardText,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { useParams } from "react-router-dom";
import appAxios from "../helpers/axios";
import addToast, { TOAST_WARNING } from "../helpers/toastr";
import Numbers from "./Numbers";

const FormCode = () => {
  const [code, setCode] = useState("");
  const [contract, setContract] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [verified, setVerified] = useState(false);

  let { token } = useParams();

  useEffect(() => {
    (async function verifyToken() {
      setIsLoading(true);
      try {
        // Call api to confirm token
        const response = await appAxios.get("/booking/verifyToken.php", {
          params: { token },
        });
        response && setContract(response.data.contract);
      } catch (error) {
        addToast(
          error.response?.data?.api_error || "Une erreur est survenue.",
          TOAST_WARNING
        );
      }
      setIsLoading(false);
    })();
  }, [token]);

  useEffect(() => {
    (async function verifyCode() {
      const regex = new RegExp("^\\d{7}(?:\\d{2})?$");
      if (regex.test(code)) {
        setIsLoading(true);
        try {
          // Call api to confirm code
          await appAxios.get("/booking/validSmsOrEmailBooking.php", {
            params: {
              bookingId: contract.id_contrat,
              codeSMSBooking: code,
            },
          });
          setVerified(true);
        } catch (error) {
          addToast(
            error.response?.data?.api_error || "Une erreur est survenue.",
            TOAST_WARNING
          );
        }
        setIsLoading(false);
      }
    })();
  }, [code]); // eslint-disable-line react-hooks/exhaustive-deps

  const addNumber = (i) => {
    setCode(code + i);
  };

  return isLoading ? (
    <Row className="mt-4">
      <Spinner
        style={{ width: "3rem", height: "3rem" }}
        className="m-auto"
        color="primary"
      />
    </Row>
  ) : !contract ? (
    <Row className="mt-4 small-device-margin">
      <Alert color="warning" className="text-center">
        Mauvais identifiant de connexion, vous pouvez demander un renvoi de{" "}
        <strong>sms</strong> ou d'<strong>email</strong>
      </Alert>
    </Row>
  ) : contract.date_validation_sms || verified ? (
    <Row className="mt-4 small-device-margin">
      <Alert color="success" className="text-center">
        Félicitations ! Votre contrat à {verified ? "bien" : "déjà"} été signé !
        {verified && (
          <>
            <br />
            Vous allez reçevoir un mail de confirmation avec votre contrat.
          </>
        )}
      </Alert>
    </Row>
  ) : (
    <>
      <Row xs="6" className="mt-4 small-device-margin">
        <Card body>
          <CardTitle tag="h5" className="title-form d-flex justify-content-center">
             Code de vérification
          </CardTitle>
          <CardText className="subtitle-form ">
            Veuillez renseigner le code que vous avez précedemment reçu par{"  "}
            <strong>email</strong> ou <strong>sms</strong>
          </CardText>
          <Form className="content-form">
            <FormGroup>
              <Input
                type="text"
                name="code"
                id="code"
                placeholder="0123456"
                onChange={(e) => setCode(e.target.value)}
                value={code}
              />
            </FormGroup>

              <div className="content-number">
                  <Numbers addNumber={addNumber} />
              </div>
          </Form>


        </Card>
      </Row>

    </>
  );
};

export default FormCode;
