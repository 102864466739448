import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";
import logoDarkPng from "../design/images/logo-dark.png";

const Menu = () => {
  return (
    <Navbar color="light" light expand="md" className="d-flex justify-content-center align-items-center">
      <NavbarBrand
        href="https://www.atout-box.fr/"
        className="ml-2 text-secondary "
        style={{ marginLeft: "20px" }}
      >
          <img src={logoDarkPng} alt="" height="100%" width="auto" />
      </NavbarBrand>
    </Navbar>
  );
};

export default Menu;
