import React from "react";
import { Jumbotron, Container, Row } from "reactstrap";

const Top = () => {
  return (
    <Row className="bg-white rounded text-center small-device-margin">
      <Jumbotron className="mt-4 mb-4">
        <Container>
          <h1 className="title-welcome">Bienvenue</h1>
          <p className="subtitle-welcome">
            Ici vous pouvez signer votre contrat{" "}
            <strong>électroniquement</strong> !!
          </p>
          <i className="fal fa-arrow-circle-down picto"/>
        </Container>
      </Jumbotron>
    </Row>
  );
};

export default Top;
